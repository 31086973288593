<template>
<div>
  <div class="page-banner">
    <img v-lazy="banner" class="img-center">
  </div>
  <div class="container">
    <div class="padding-80">
      <div class="common-title">
        <h1>{{ $t("menu.partner") }}</h1>
        <div class="common-line"></div>
      </div>

      <div class="partner-list">
        <ul>
          <li v-for="item in links" :key="item">
            <a :href="item.link" target="_blank">
              <img :src="item.image">
            </a>
          </li>
        </ul>
      </div>

      <div class="padding-t-80">
        <div class="common-title">
          <h1>{{ $t("menu.inquiry") }}</h1>
          <div class="common-line"></div>
        </div>
        <inquiry-form :inquiryBg="inquiryBg" />
      </div>


     </div>
  </div>
</div>
</template>

<script>
import {getPartner, getSupport} from "@/network/main";
import inquiryForm from "@/views/supportDetail/inquiryForm";

export default {
  name: "Partner",
  components:{
    inquiryForm,
  },
  data(){
    return{
      banner:{},
      links:[],
      inquiryBg:{},
    }
  },
  created() {
    getPartner().then(res => {
      this.banner = res.data.data.banner;
      this.links = res.data.data.partner_with_us;
    })

    getSupport().then(res => {
       this.inquiryBg = res.data.data.info.image_one;
    })
  },
}
</script>

<style scoped>
.partner-list ul {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
}

.partner-list ul li{
  width: 23%;
  height: 90px;
  margin: 40px 1% 0 1%;

  border: 1px solid #C6C6C6;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items:  center;
  justify-content: center;
  padding: 10px 0;
}

.partner-list ul li img{
  display: block;
  margin: 0 auto;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.partner-list ul li:hover img{
  -webkit-transform:scale(1.07);
  -moz-transform:scale(1.07);
  -ms-transform:scale(1.07);
  -o-transform: scale(1.07);
  transform: scale(1.07);
}

@media only screen and (min-width:0px) and (max-width:767px){
  .partner-list ul li{
    width: 48%;
    margin: 20px 1% 0 1%;

  }
}



</style>